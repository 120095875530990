import React, { useEffect, useState } from "react";

import { Profile, ProfileCheckoutStep } from "./ProfilePage";

type ComposeCheckoutStepProps = {
  profile: Profile;
  setEmailBody: (emailBody: string) => void;
  setEmailSubject: (emailSubject: string) => void;
  setStep: (step: ProfileCheckoutStep | null) => void;
};

function ComposeCheckoutStep(
  props: ComposeCheckoutStepProps,
): React.ReactElement {
  const onChangeEmailSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setEmailSubject(e.target.value);
  };

  const onChangeEmailBody = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setEmailBody(e.target.value);
  };

  return (
    <div className="flex flex-col items-center p-6 md:p-12">
      <h1 className="text-4xl font-bold mb-12">Write your email</h1>
      <div className="flex flex-col items-start w-full md:w-1/2 mb-12">
        <input
          onChange={onChangeEmailSubject}
          placeholder="Subject line..."
          className="mb-6 w-full h-12 p-4 rounded-md border border-slate-300"
        />
        <textarea
          onChange={onChangeEmailBody}
          placeholder="Write your email here..."
          className="w-full h-96 max-w-full p-4 rounded-md border border-slate-300"
        />
      </div>
      <div className="flex flex-col items-end w-full md:w-1/2">
        <button
          className="px-6 py-3 rounded-md bg-emerald-900 hover:bg-emerald-800 text-white font-medium text-l"
          onClick={() => props.setStep(ProfileCheckoutStep.Billing)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ComposeCheckoutStep;
