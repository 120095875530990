import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ProfileCheckout from "./ProfileCheckout";

type ProfileRouteParams = {
  profileHandle: string;
};

export type Profile = {
  name: string;
  firstName: string;
  bio: string;
  photoUrl: string;
  feeInCents: number;
};

export enum ProfileCheckoutStep {
  Email = "EMAIL",
  Compose = "COMPOSE",
  Billing = "BILLING",
}

const JAMES_BIO = `Father, Tar Heel, and engineering manager for the growth team at Substack.
This is a public-facing inbox that I've set up to let anybody send me an email if they want to.
The only catch is that you have to pay me $10. This weeds out the spammers and the scammers and 
ensures that I'm only getting email from people with something important to say. I plan to donate 
100% of proceeds to GiveWell -- the point isn't for me to make money, it's to impose a cost on 
spammers and scammers.`;

function ProfilePage() {
  const { profileHandle } = useParams<ProfileRouteParams>();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [checkoutStep, setCheckoutStep] = useState<ProfileCheckoutStep | null>(
    null,
  );

  useEffect(() => {
    // TODO: Load profile from backend
    const profile: Profile = {
      name: "James Kylstra",
      firstName: "James",
      bio: "Father, Tar Heel, engineering manager of the growth team @Substack.",
      photoUrl: "https://highsignal.net/images/jjk_headshot.jpeg",
      feeInCents: 1000,
    };
    setProfile(profile);
  }, []);

  if (!profile) {
    return null;
  }

  // TODO: parameterize by handle
  if (profileHandle !== "@jameskylstra") {
    return null;
  }

  if (checkoutStep) {
    return (
      <ProfileCheckout
        profile={profile}
        step={checkoutStep}
        setStep={setCheckoutStep}
      />
    );
  }

  return (
    <div className="flex flex-col items-center p-6 md:p-12 md:w-1/2 md:mx-auto">
      <div className="border border-slate-300 rounded-md min-h-40 min-w-40 max-h-40 max-w-40 mb-12">
        <img src={profile.photoUrl} className="h-full w-full rounded-md" />
      </div>
      <h1 className="text-4xl font-bold mb-12">
        Hi, I'm James. Let's get in touch.
      </h1>
      <p className="text-xl mb-6">
        I'm a software engineer and former lawyer currently working as the
        engineering manager for the growth team at{" "}
        <a href="https://substack.com" className="text-blue-600">
          Substack
        </a>
        .
      </p>
      <p className="text-xl mb-6">
        This is a public-facing inbox I've set up so that anybody in the world
        can send me an email. If you email me through this site, I promise that
        I'll read every word of it.{" "}
        <strong>The only catch is that you have to pay $10.</strong>
      </p>
      <p className="text-xl mb-12">
        This isn't a way for me to make money; in fact, I'm donating 100% of the
        proceeds to{" "}
        <a href="https://givewell.org" className="text-blue-600">
          GiveWell
        </a>
        . This is to weed out the spammers and scammers and to ensure that I'm
        only getting email from people with something important to say. If
        that's you, hit me up!
      </p>
      <div className="flex flex-row-reverse justify-items-start">
        <button
          className="px-6 py-3 rounded-md bg-emerald-900 hover:bg-emerald-800 text-white font-medium text-l"
          onClick={() => setCheckoutStep(ProfileCheckoutStep.Email)}
        >
          Email me
        </button>
      </div>
    </div>
  );
}

export default ProfilePage;
