import React, { useEffect, useState } from "react";

import { Profile, ProfileCheckoutStep } from "./ProfilePage";

type AltBillingCheckoutStepProps = {
  profile: Profile;
  sendEmail: () => void;
  setStep: (step: ProfileCheckoutStep | null) => void;
};

function AltBillingCheckoutStep(
  props: AltBillingCheckoutStepProps,
): React.ReactElement {
  useEffect(() => {
    props.sendEmail();
  }, []);

  return (
    <div className="flex flex-col items-center p-6 md:p-12">
      <h1 className="text-4xl font-bold mb-6">It's your lucky day</h1>
      <h2 className="text-xl text-center w-full md:w-1/2 mb-12">
        While HighSignal is in beta, your email is free. We've sent your email
        to {props.profile.firstName}.
      </h2>
      <button
        onClick={() => props.setStep(null)}
        className="px-6 py-3 rounded-md bg-emerald-900 hover:bg-emerald-800 text-white font-medium text-l"
      >
        Finish
      </button>
    </div>
  );
}

export default AltBillingCheckoutStep;
