import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/home/HomePage";
import ProfilePage from "./pages/profile/ProfilePage";

function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen font-sans bg-gradient-to-r from-zinc-200 to-zinc-100 text-zinc-900">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:profileHandle" element={<ProfilePage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
