import React, { useEffect, useState } from "react";

import { Profile, ProfileCheckoutStep } from "./ProfilePage";
import EmailCheckoutStep from "./EmailCheckoutStep";
import ComposeCheckoutStep from "./ComposeCheckoutStep";
import AltBillingCheckoutStep from "./AltBillingCheckoutStep";

type ProfileCheckoutProps = {
  profile: Profile;
  step: ProfileCheckoutStep;
  setStep: (step: ProfileCheckoutStep | null) => void;
};

function ProfileCheckout(props: ProfileCheckoutProps): React.ReactElement {
  const [senderAddress, setSenderAddress] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailSubject, setEmailSubject] = useState<string>("");

  const sendEmail = () => {
    // TODO: implement
    console.log(
      `Sending email from ${senderAddress} with subject ${emailSubject} and body ${emailBody}`,
    );
  };

  switch (props.step) {
    case ProfileCheckoutStep.Email:
      return (
        <EmailCheckoutStep
          profile={props.profile}
          setSenderAddress={setSenderAddress}
          setStep={props.setStep}
        />
      );
    case ProfileCheckoutStep.Compose:
      return (
        <ComposeCheckoutStep
          profile={props.profile}
          setEmailBody={setEmailBody}
          setEmailSubject={setEmailSubject}
          setStep={props.setStep}
        />
      );
    case ProfileCheckoutStep.Billing:
      return (
        <AltBillingCheckoutStep
          profile={props.profile}
          sendEmail={sendEmail}
          setStep={props.setStep}
        />
      );
    default:
      return <div>Unrecognized checkout step!</div>;
  }
}

export default ProfileCheckout;
