import React from "react";

function HomePage() {
  return (
    <div className="flex flex-row md:items-center h-screen">
      <div className="py-6 px-12 flex flex-col items-start md:w-1/2">
        <h1 className="text-xl text-emerald-900 my-6">HighSignal</h1>
        <h2 className="text-5xl font-bold mb-6">
          Money is the best spam filter
        </h2>
        <p className="text-xl my-6">
          HighSignal gives you a high signal public inbox where people can
          message you — <i>after</i> they pay a nominal fee. That weeds out the
          spammers and makes it so you only hear from people with something
          important to say. Give the public a way to reach you, without wrecking
          your inbox.
        </p>
        <div className="flex flex-row">
          <button className="my-6 px-6 py-3 rounded-md bg-emerald-900 hover:bg-emerald-800 text-white font-medium text-xl">
            Get started
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
