import React, { useEffect, useRef } from "react";

import { Profile, ProfileCheckoutStep } from "./ProfilePage";

type EmailCheckoutStepProps = {
  profile: Profile;
  setSenderAddress: (senderAddress: string) => void;
  setStep: (step: ProfileCheckoutStep | null) => void;
};

function EmailCheckoutStep(props: EmailCheckoutStepProps): React.ReactElement {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setSenderAddress(e.target.value);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="flex flex-col items-center p-6 md:p-12 md:w-1/2 md:mx-auto">
      <h1 className="text-4xl font-bold mb-6">What's your email address?</h1>
      <h2 className="text-xl mb-12">
        I need this so that I can reply to you if the situation calls for it.
      </h2>
      <form
        onSubmit={() => props.setStep(ProfileCheckoutStep.Compose)}
        className="flex flex-col"
      >
        <input
          type="email"
          ref={inputRef}
          onChange={onChange}
          placeholder="Your email address..."
          className="mb-12 w-96 max-w-full h-12 p-4 rounded-md border border-slate-300"
        />
        <div className="block text-center">
          <button
            type="submit"
            className="px-6 py-3 rounded-md bg-emerald-900 hover:bg-emerald-800 text-white font-medium text-l m-auto"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmailCheckoutStep;
